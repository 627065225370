import React, { Component } from 'react';
import Loading from '../Loading';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import axios from 'axios';
import { APIURL } from '../../config/config';
import { save } from 'save-file';
import { CarUtil } from '../../utils/carUtil';
import { connect } from 'react-redux'
import { NumberUtil } from '../../utils/number-util'
import { checkGroupPermission, getGroupPermission } from '../../services/grouppermission';
import { AlertWarning } from '../Alert/Alert';
import { format } from 'date-fns';
import { th } from 'date-fns/locale';
import { DateUtil } from '../../utils/dateUtil'
// import { formatWithOptions } from 'date-fns/fp';
// const dateToString = formatWithOptions({locale: th}, 'dd MMMM yyyy');

class CarInReport extends Component{
  constructor (props) {
    super(props);
    this.state = {
      menuID: [107, 117],
      grouppermissions: [],
      user: {},
      isLoading: false,
      date_start: new Date(),
      date_end: new Date(),
      lists: [],
      data_by: 'all', // all, byDate
      branches: [],
      branch_id: '',
      orders: [
        {order_id: 'car_in_asc', order_name: 'วันที่ซื้อรถเข้า : เก่าไปใหม่'},
        {order_id: 'car_in_desc', order_name: 'วันที่ซื้อรถเข้า : ใหม่ไปเก่า'},
        {order_id: 'car_license_asc', order_name: 'ทะเบียนรถใหม่ : ก-ฮ'},
        {order_id: 'car_license_desc', order_name: 'ทะเบียนรถใหม่ : ฮ-ก'},
      ],
      order_by: 'car_in_desc',
      person_vat_id: '',
      is_convert_date: 0
    }
    this.handleOnChange = this.handleOnChange.bind(this)
    this.handleDateStart = this.handleDateStart.bind(this)
    this.handleDateEnd = this.handleDateEnd.bind(this)
    this.loadCarInData = this.loadCarInData.bind(this)
    this.exportCarInData = this.exportCarInData.bind(this)
    this.loadBranchData = this.loadBranchData.bind(this)
    this.showBranchSummary = this.showBranchSummary.bind(this)
    this.loadGroupPermission = this.loadGroupPermission.bind(this)
  }

  loadGroupPermission() {
    let type = this.props.type
    let menuID = this.state.menuID
    if (type === undefined) {

      return
    }

    getGroupPermission(type, menuID).then(res => {
      if(res.data.result === 'success') {
        this.setState({
          grouppermissions: res.data.grouppermissions
        })
      }
    }).catch(e => {
      console.log('error: ', e)
      this.setState({
        grouppermissions: []
      })
    })
  }

  componentDidMount () {
    this.loadBranchData()
    this.loadGroupPermission()
  }

  // defaultChecked={this.state.data_by === 'byDate'}
  // defaultChecked={this.state.data_by === 'all'}

  handleDateStart(date){
    this.setState({
      date_start: date
    })
  }

  handleDateEnd(date){
    this.setState({
      date_end: date
    })
  }

  handleOnChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  loadCarInData() {
    if(this.state.date_start > this.state.date_end){
      AlertWarning('วันที่เริ่มต้น มากกว่า วันที่สิ้นสุด กรุณาตรวจสอบ')
      return
    }

    // let start_date =  moment(this.state.date_start).format('YYYY-MM-DD')
    // let end_date   =  moment(this.state.date_end).format('YYYY-MM-DD')

    let start_date = format(new Date(this.state.date_start), 'yyyy-MM-dd')
    let end_date = format(new Date(this.state.date_end), 'yyyy-MM-dd')

    this.setState({
      isLoading: true
    })

    const data_by = this.state.data_by
    const branch_id = this.state.branch_id
    const order_by = this.state.order_by
    const person_vat_id = this.state.person_vat_id

    const data = {
      start_date: start_date,
      end_date: end_date,
      data_by: data_by,
      branch_id: branch_id,
      order_by: order_by,
      person_vat_id: person_vat_id
    }

    // axios.get(APIURL + '/report/carin/'+start_date+'/'+end_date)
    axios.post(APIURL + '/report/carin', data)
      .then(res => {
        this.setState({
          isLoading: false,
          lists: res.data
        }, () => {
          this.showBranchSummary()
        })

      }).catch(error => {
       console.log('error:', error)
    })

  }

  showBranchSummary() {

  }

  async exportCarInData() {
    if(this.state.date_start > this.state.date_end){
      AlertWarning('วันที่เริ่มต้น มากกว่า วันที่สิ้นสุด กรุณาตรวจสอบ')
      return
    }

    // let start_date =  moment(this.state.date_start).format('YYYY-MM-DD')
    // let end_date   =  moment(this.state.date_end).format('YYYY-MM-DD')

    let start_date = format(new Date(this.state.date_start), 'yyyy-MM-dd')
    let end_date = format(new Date(this.state.date_end), 'yyyy-MM-dd')

    const data_by = this.state.data_by
    const branch_id = this.state.branch_id
    const order_by = this.state.order_by
    const person_vat_id = this.state.person_vat_id
    const is_convert_date = this.state.is_convert_date

    let viewed = checkGroupPermission(107, this.state.grouppermissions).viewed

    this.setState({
      isLoading: true
    })

    const data = {
      start_date: start_date,
      end_date: end_date,
      data_by: data_by,
      branch_id: branch_id,
      viewed: viewed,
      order_by: order_by,
      person_vat_id: person_vat_id,
      is_convert_date: is_convert_date,
    }

    // axios.get(APIURL + '/report/carin/'+start_date+'/'+end_date)
    axios.post(APIURL + '/report/carinexport', data)
      .then(async res => {

        const excelBuffer = res.data.excelBuffer
        const currentDatetime = res.data.currentDatetime
        const fileName = 'รายงานสต๊อกรถ_export_'+currentDatetime+'.xlsx'

        this.setState({
          isLoading: false
        })

        await save(excelBuffer, fileName)

      }).catch(error => {
      console.log('error :', error)
    })
  }

  loadBranchData () {
    axios.get(APIURL + '/branch/')
      .then(res => {
        this.setState({branches: res.data}, () => this.loadCarInData())
      }).catch(error => {
      this.setState({branches: []})
    })
  }

  render () {
    const {
      lists,
      is_convert_date,
      // sum_price,
      // sum_car_cost,
      // sum_car_cost_repair,
      // sum_car_cost_other,
      // sum_profit
    } = this.state

    // const usertype = this.props.type
    const viewed = checkGroupPermission(107, this.state.grouppermissions).viewed

    const emptyBody = <tr><td colSpan={21} className="text-center text-danger" > ไม่พบข้อมูล </td></tr>

    let sumCarCost = 0
    let sumCarSalePrice = 0
    let sumVatBuy = 0
    let sumCostOther = 0
    let sumCostRepair = 0
    const listRows = lists.map((car, index) => {

      // const car_date_in = moment(car.car_date_in).locale('th').format('LL')
      let car_date_in = format(new Date(car.car_date_in), 'dd MMMM yyyy',  { locale: th });

      if (is_convert_date === 1) {
        car_date_in = format(new Date(car.car_date_in), 'yyyy-MM-dd');
      }

      // const car_sell_date = car.car_sell_date === null ? '-' : moment(car.car_sell_date).locale('th').format('LL')
      /* const car_cost = car.car_cost
      const car_cost_repair = car.car_cost_repair
      const car_cost_other = car.car_cost_other
      // const gross_profit = car.real_sell_price - (car_cost+car_cost_repair+car_cost_other)
      const real_sell_price = car.real_sell_price === null ? '-': car.real_sell_price*/

      let car_cost = parseFloat(String(car.car_cost)).toFixed(2)
      let car_sale_price = parseFloat(String(car.car_sale_price)).toFixed(2)
      let vat_buy = parseFloat(String(car.vat_buy)).toFixed(2)
      let car_cost_repair = parseFloat(String(car.car_cost_repair)).toFixed(2)

      let cost_operation = car.cost_operation;
      let cost_service = car.cost_service;
      let cost_transport = car.cost_transport;
      let cost_porobor_lan = car.cost_porobor_lan;
      let cost_bangkhab_phasi_lan = car.cost_bangkhab_phasi_lan;
      let cost_bangkhab_xon_lan = car.cost_bangkhab_xon_lan;
      let cost_check_ton = car.cost_check_ton;
      let car_cost_other_sum = car.car_cost_other + car.car_cost_expenses_other + car.car_cost_commissions + car.car_cost_act + car.car_cost_tax

      car_cost_other_sum += Number(cost_operation);
      car_cost_other_sum += Number(cost_service);
      car_cost_other_sum += Number(cost_transport);
      car_cost_other_sum += Number(cost_porobor_lan);
      car_cost_other_sum += Number(cost_bangkhab_phasi_lan);
      car_cost_other_sum += Number(cost_bangkhab_xon_lan);
      car_cost_other_sum += Number(cost_check_ton);

      sumCarCost += Number(car_cost)
      sumCarSalePrice += Number(car_sale_price)
      sumVatBuy += Number(vat_buy)
      sumCostOther += Number(car_cost_other_sum)
      sumCostRepair += Number(car_cost_repair)

      let qtyDate = 0
      let start = moment(car.car_date_in)
      if (car.car_sell_date === null) {
        // วันจอดถึงวันปัจจุบัน
        let end = moment(new Date())
        qtyDate = end.diff(start, 'days')
      } else {
        // วันจอดถึงวันขาย
        let end = moment(car.car_sell_date)
        qtyDate = end.diff(start, 'days')
      }

    // <td className="text-right">{real_sell_price}</td>
    // <td className="text-right">{car.car_cost.toLocaleString()}</td>
    // <td className="text-right">{car.car_cost_repair.toLocaleString()}</td>
    // <td className="text-right">{car.car_cost_other.toLocaleString()}</td>

      let car_date_tax = car.car_date_tax
      let car_date_tax_text = DateUtil.convertShortDateThaiDash(car_date_tax)
      let date_ago = car_date_tax !== null && car_date_tax !== '0000-00-00' ? DateUtil.carDateTaxOverdue(car_date_tax) : '-'

      return (
        <tr key={index}>
          <td className="text-center">{index+1}</td>
          <td>{car.car_name}</td>
          <td className="text-center text-nowrap">{car.car_brand_name}</td>
          <td className="text-center text-nowrap">{car.car_structure_name}</td>
          <td className="text-center">{car.car_license_plate_new} {car.province_name_new}</td>
          <td className="text-center">{car.car_license_plate_old} {car.province_name_old}</td>
          <td className="text-center text-nowrap">{car_date_in}</td>

          <td> {date_ago === '-' ? '-' : (<span className="text-danger">{date_ago}</span>)} </td>
          <td> {car_date_tax_text} </td>

          <td className="text-center text-nowrap">{car.buy_car_from}</td>
          <td className="text-center">{CarUtil.convertDepositRegistration(car.deposit_registration_id)}</td>
          <td className="text-center text-nowrap">
            { car.receipt_date !==null  && car.receipt_date!=='0000-00-00'? moment(car.receipt_date).locale('th').format('LL') : '-'}
          </td>
          <td className="text-center text-nowrap">{CarUtil.convertLicenseIdToName(car.liciense_status_id)}</td>

          <td className="text-center text-nowrap">{CarUtil.convertVatPersonToName(car.person_vat_id)}</td>

          <td className="text-center text-nowrap">{car.finance_name}</td>
          <td className="text-center text-nowrap">{car.branch_name}</td>
          <td className="text-center text-nowrap">{qtyDate}</td>
          <td className="text-center text-nowrap">{car.car_status_name}</td>

          <td className={ viewed ? "text-right" : "hidden"} > {NumberUtil.addCommas(String(car.car_cost))} </td>
          <td className={ viewed ? "text-right" : "hidden"} > {NumberUtil.addCommas(String(vat_buy))} </td>
          <td className={ viewed ? "text-right" : "hidden"} > {NumberUtil.addCommas(String(car_cost_repair))} </td>
          <td className={ viewed ? "text-right" : "hidden"} > {NumberUtil.addCommas(String(car_cost_other_sum))} </td>
          <td className={ viewed ? "text-right" : "hidden"} > {NumberUtil.addCommas(String(car.car_sale_price))} </td>
        </tr>
      )
    });


    let sumRow = <tr>
      <td colSpan={18}  className="text-right font-weight-bold" >รวม</td >
      <td className="font-weight-bold" >{NumberUtil.addCommas(String(parseFloat(sumCarCost).toFixed(2)))}</td>
      <td className="font-weight-bold" >{NumberUtil.addCommas(String(parseFloat(sumVatBuy).toFixed(2)))}</td>
      <td className="font-weight-bold" >{NumberUtil.addCommas(String(parseFloat(sumCostRepair).toFixed(2)))}</td>
      <td className="font-weight-bold" >{NumberUtil.addCommas(String(parseFloat(sumCostOther).toFixed(2)))}</td>
      <td className="font-weight-bold" >{NumberUtil.addCommas(String(parseFloat(sumCarSalePrice).toFixed(2)))}</td>
    </tr>

    const tableRows = lists.length === 0 ? emptyBody : listRows

    let car_branch_list = this.state.branches.map((branch, index) => {
      return (
        <option key={index} value={branch.branch_id}>{branch.branch_name}</option>
      )
    });

    let order_list = this.state.orders.map((order, index) => {
      return (
          <option key={index} value={order.order_id}>{order.order_name}</option>
      )
    });

    let width_box = 128

    // if (width <= 980){
    //   width_box = 112
    // }

    return (
      <div>
        <Loading isLoading={this.state.isLoading} />

        <div className="card-block" style={styles.overflowY}>
          <div className="row">

              <div className="card card-accent-primary">

                <div className="card-header">
                  <div className="row col-12 mt-1">
                    <div className="col-6">
                      <strong className="text-title">
                        {
                          this.state.data_by === 'allWithSell' ? 'รายงานการซื้อรถ (ตามวันที่ + ทั้งหมด(รวมขายแล้ว))' : 'รายงานสต๊อกรถ (เฉพาะรถที่ยังไม่ขาย)'
                        }
                        </strong>
                    </div>
                    <div className="col-3">

                    </div>

                      {
                        this.state.lists.length === 0 ? '': (
                          <div className="col-3 text-center pt-2 pb-1 bg-primary">
                              <h5>
                                พบ&nbsp; {this.state.lists.length} &nbsp;คัน
                              </h5>
                          </div>
                        )
                      }

                  </div>
                </div>

                <div className="card-block">

                  <div className="row mt-1">
                    <div className="mt-1 ml-4">
                      จาก
                    </div>

                    <div className="form-group  ml-2" style={{width: 130}} >
                      {this.state.data_by === 'all'?
                          <DatePicker
                              selected={this.state.date_start}
                              onChange={this.handleDateStart}
                              dateFormat="yyyy-MM-dd"
                              type="text"
                              className="form-control"
                              disabled
                              placeholder=""
                          /> :
                          <DatePicker
                              selected={this.state.date_start}
                              onChange={this.handleDateStart}
                              dateFormat="yyyy-MM-dd"
                              type="text"
                              className="form-control"
                              placeholder=""
                          />
                      }
                    </div>
                   {/* <div className="col-1 text-center">
                      ถึง
                    </div>*/}
                    <div className="form-group ml-1" style={{width: 130}}>
                      {this.state.data_by === 'all' ?
                          <DatePicker
                              selected={this.state.date_end}
                              onChange={this.handleDateEnd}
                              dateFormat="yyyy-MM-dd"
                              type="text"
                              className="form-control"
                              disabled
                              placeholder=""
                          />
                       :
                      <DatePicker
                          selected={this.state.date_end}
                          onChange={this.handleDateEnd}
                          dateFormat="yyyy-MM-dd"
                          type="text"
                          className="form-control"
                          placeholder=""
                      />
                      }
                    </div>

                    <div className="form-group ml-1" style={{width: 160}}>
                      <select className="form-control"
                              id="data_by" name="data_by"
                              onChange={this.handleOnChange}
                              value={this.state.data_by}
                      >
                        <option value='byDate'> ตามวันที่ </option>
                        <option value='all'> ทั้งหมด(ยังไม่ขาย) </option>
                        <option value='allWithSell'> ตามวันที่ + ทั้งหมด(รวมขายแล้ว) </option>
                      </select>
                    </div>

                    <div className="form-group ml-1" style={{width: 130}}>
                      <select className="form-control" id="branch_id" name="branch_id"
                        onChange={(e) => {
                          this.setState({
                            branch_id: e.target.value
                          })
                        }}
                      >
                        <option value=''> ตามสาขา </option>
                        {car_branch_list}
                      </select>
                    </div>

                    <div className="form-group ml-1" style={{width: 150}}>
                      <select className="form-control"
                              id="person_vat_id"
                              name="person_vat_id"
                              value={this.state.person_vat_id}
                              onChange={(e) => {
                                this.setState({
                                  person_vat_id : e.target.value
                                })
                              }}
                      >
                        <option value="0">ประเภทการซื้อรถ</option>
                        <option value="1">บุคคล/รถบ้าน</option>
                        <option value="2">รถ VAT</option>
                        <option value="3">รถฝากขาย</option>
                      </select>
                    </div>

                    <div className="form-group ml-1" style={{width: 120}}>
                      <select className="form-control mr-1"
                              name="order_by"
                              style={{...styles.selectFilter, width: width_box}}
                              value={this.state.order_by}
                              onChange={(e) => {
                                this.setState({order_by: e.target.value})
                              }}
                      >
                        <option value="">เรียงตาม</option>
                        {order_list}
                      </select>
                    </div>

                    {/*<div className="form-group mt-1 ml-3">
                      <input type="radio"
                             name="data_by"
                             value="byDate"
                             className="btn btn-primary"
                             checked={this.state.data_by === 'byDate'}
                             onChange={this.handleOnChange}
                      /> &nbsp;<span style={{marginTop: -4}}>ตามวันที่</span>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <input type="radio"
                             name="data_by"
                             value="all"
                             className="btn btn-primary"
                             checked={this.state.data_by === 'all'}
                             onChange={this.handleOnChange}
                      /> &nbsp;<span style={{marginTop: -4}}>ทั้งหมด</span>
                    </div>*/}

                    <div className="col-1 ml-4 mr-1">
                      <button type="button" style={{width: 80}} className="btn btn-sm btn-primary" onClick={() => this.loadCarInData()} > ตกลง </button>
                    </div>

                    {
                      checkGroupPermission(117, this.state.grouppermissions).printed ? (
                        <div className="col-1">
                          <button type="button" style={{width: 80}} className="btn btn-sm btn-success" onClick={() => this.exportCarInData()} > Excel </button>
                        </div>
                      ) :null
                    }

                  </div>

                  <div className="form-group row ml-2 mt-0">
                    {/*<label className="col-md-3 form-control-label">&nbsp;</label>*/}
                    <div className="col-md-9">
                      <div className="checkbox">
                        <label htmlFor="is_convert_date">
                          <input
                              type="checkbox"
                              name="is_convert_date"
                              style={{marginTop: 4}}
                              value={this.state.is_convert_date}
                              checked={this.state.is_convert_date === 1}
                              onChange={(e) => {
                                let value = e.target.value === '0' ? 1 : 0
                                // console.log('value=', value)
                                this.setState({
                                  is_convert_date: value
                                })
                              }}
                          />
                          &nbsp;ใช้วันที่ซื้อเข้าเป็นวันที่อย่างย่อ 2024-08-31
                        </label>
                      </div>
                      {/* <div className="checkbox">
                                            <label htmlFor="checkbox2">
                                                <input type="checkbox" id="checkbox2" name="checkbox2" value="option2"/> Option 2
                                            </label>
                                        </div>
                                        <div className="checkbox">
                                            <label htmlFor="checkbox3">
                                                <input type="checkbox" id="checkbox3" name="checkbox3" value="option3"/> Option 3
                                            </label>
                                        </div>*/}
                    </div>
                  </div>

                  <div className="row mt-1">
                    <div className="col-12">
                      <table className="table table-striped table-responsive">
                        <thead>
                        <tr>
                          <th className="text-center">ลำดับ</th>
                          <th style={{width: 200}} className="text-center text-nowrap">ชื่อรถ</th>
                          <th style={{width: 50}} className="text-center text-nowrap">ยี่ห้อ</th>
                          <th style={{width: 50}} className="text-center text-nowrap">ลักษณะ</th>
                          <th style={{width: 50}} className="text-center text-nowrap">ทะเบียนใหม่</th>
                          <th style={{width: 50}} className="text-center text-nowrap">ทะเบียนเดิม</th>
                          <th className="text-center text-nowrap" style={{width: 100}}>วันที่ซื้อรถเข้า</th>

                          <th style={{width: 150}} className=" text-nowrap">ภาษีขาด</th>
                          <th style={{width: 150}} className=" text-nowrap">วันครบกำหนดต่อภาษี</th>

                          <th className="text-center text-nowrap"> ซื้อรถจาก </th>
                          <th className="text-center text-nowrap">มัดจำเล่ม</th>
                          <th className="text-center text-nowrap">วันที่รับเล่ม</th>
                          <th className="text-center text-nowrap">สถานะเล่ม</th>
                          <th className="text-center text-nowrap">ประเภทการซื้อรถ</th>
                          <th style={{width: 60}} className="text-center text-nowrap">ไฟแนนซ์เล่ม</th>
                          <th style={{width: 60}} className="text-center">สาขา</th>
                          <th className="text-center text-nowrap">จ.น.วันที่รถจอด</th>
                          <th className="text-center">สถานะ</th>

                          <th className={viewed ? "text-center text-nowrap" : "hidden"}>ราคาซื้อเข้า</th>
                          <th className={viewed ? "text-center text-nowrap" : "hidden"}>VAT ซื้อ</th>
                          <th className={viewed ? "text-center text-nowrap" : "hidden"}>ทุนซ่อม</th>
                          <th className={viewed ? "text-center text-nowrap" : "hidden"}>ทุนอื่นๆ</th>
                          <th className={viewed ? "text-center text-nowrap" : "hidden"}>ราคาขาย</th>

                        </tr>
                        </thead>
                        <tbody>

                        {tableRows}

                        {
                          viewed ? sumRow: null
                        }

                        </tbody>
                      </table>
                    </div>
                  </div>

                </div>
              </div>

          </div>
        </div>

      </div>
    )
  }
}
// visible
// overflow: 'scroll',
const styles = {
  overflowY: {
    overflow: 'scroll',
  }
}

// const mapStateToProps = (state) => ({
//   user: state.auth.user,
//   token: state.auth.token,
//   isAuthenticated: state.auth.isAuthenticated
// })
const mapStateToProps = (state) => ({
  user: state.auth.user,
  token: state.auth.token,
  type: state.auth.type,
  isAuthenticated: state.auth.isAuthenticated
})

// export default CarInReport;
export default connect(mapStateToProps)(CarInReport);
