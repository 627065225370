import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as moment from 'moment';
import 'moment/locale/th';
import NumberFormat from 'react-number-format';
import {IMAGE_FULL_URL, APIURL, IMAGE_NOPHOTO_URL, CLOUDFRONT_URL} from '../../../config/config'
import PreviewImage from '../../../views/PreviewImage/';
import {  Modal, ModalBody } from 'reactstrap';
import axios from 'axios/index';
import {checkFormControls, checkGroupPermission} from '../../../services/grouppermission'
import CarExpenseFormEdit from '../CarExpenseFormEdit'
import CarExpenseFile from '../CarExpenseFile'
import { AlertError } from '../../Alert/Alert'

class CarExpenseList extends Component {

    constructor (props) {
        super(props)

        this.state = {
            preview_image_url: '',
            preview_image_select: false,
            isOpenCarExpenseFormEdit: false,
            expense_selected: {},
            isOpenCarExpenseImage: false
        }
        this.togglePreviewImage = this.togglePreviewImage.bind(this)
        this.onDeleteHandle = this.onDeleteHandle.bind(this)
        this.onOpenCarExpenseFormEdit = this.onOpenCarExpenseFormEdit.bind(this)
        this.onToggleCarExpenseFormEdit = this.onToggleCarExpenseFormEdit.bind(this)
        this.onToggleCarExpenseImage = this.onToggleCarExpenseImage.bind(this)
    }

    onPreviewSelected(url){
        this.setState({
            preview_image_url: url,
            preview_image_select: true
        });
    }

    togglePreviewImage() {
        this.setState({
            preview_image_select: !this.state.preview_image_select
        });
    }

  onDeleteHandle(expense){
    if(window.confirm('ยืนยันการลบรายการ '+ expense.expense_name)) {
      let expense_id = expense.expense_id
      let car_id = this.props.car_id
      let expense_image_file = expense.expense_image_file
      let data = {
        expense_image_file: expense_image_file,
        expense_amount: expense.expense_amount
      }

      let url = APIURL + '/expense/' + expense_id+'/'+car_id

      axios.put(url, data) //1 = ค่าใช้จ่ายของรถ
        .then(res => {
          if (res.status === 200) {
            this.props.onReload()
          }
        }).catch(error => {
        console.log(error)
      })
    }
  }

  onToggleCarExpenseFormEdit(){
    this.setState({
      isOpenCarExpenseFormEdit: !this.state.isOpenCarExpenseFormEdit
    })
  }

  onToggleCarExpenseImage() {
    this.setState({
      isOpenCarExpenseImage: !this.state.isOpenCarExpenseImage
    })
  }

  onOpenCarExpenseFormEdit(expense) {
      this.setState({
        isOpenCarExpenseFormEdit: true,
        expense_selected: expense
      })
  }

  onOpenCarExpenseImage(expense) {
    this.setState({
      isOpenCarExpenseImage: true,
      expense_selected: expense
    })
  }

    render(){

      const form_controls = this.props.form_controls
      const sell_status_check = this.props.sell_status_check
      const expense_type_id = this.props.expense_type_id
      const menuID = this.props.menuID

      let sum_expense_cost = this.props.car_expense_list.length === 0 ? 0 : this.props.car_expense_list.reduce((acc, expense) => {
        if (expense.include_vat === 1 ) {
          // 1 ยอด expense_amount รวม vat มาแล้ว
         return acc + expense.expense_amount - expense.expense_amount_vat
        } else {
          return acc + expense.expense_amount
        }
      }, 0)
      let sum_vat = this.props.car_expense_list.length === 0 ? 0 : this.props.car_expense_list.reduce((acc, expense) => acc + expense.expense_amount_vat, 0)
      let sum_expense_vat = this.props.car_expense_list.length === 0 ? 0 : this.props.car_expense_list.reduce((acc, expense) => {
        if (expense.include_vat === 1 ) {
         return acc + expense.expense_amount
        } else {
         return acc + expense.expense_amount + expense.expense_amount_vat
        }
      }, 0)

      // + expense.expense_amount_vat
      // sum_expense_cost = sum_expense_cost - sum_vat

        let car_expense_list = this.props.car_expense_list.map((expense,index)=>{

          let image_url = ''
          if(expense.expense_image_file === 'nophoto.png') {
            image_url = IMAGE_NOPHOTO_URL
          } else {
            if (expense.expense_image_file !== null && expense.expense_image_file!== undefined) {
              if (expense.expense_image_file.includes('https')) {
                // image_url = expense.expense_image_file
                image_url = CLOUDFRONT_URL+expense.expense_image_key
              }else{
                image_url = IMAGE_FULL_URL + expense.expense_image_file
              }
            }else{
              image_url = IMAGE_NOPHOTO_URL
            }
          }


            let image_url2 = ''
            if(expense.expense_image_file2 === 'nophoto.png') {
                image_url2 = IMAGE_NOPHOTO_URL
            } else {
                if (expense.expense_image_file2 !== null && expense.expense_image_file2!== undefined) {
                    if (expense.expense_image_file2.includes('https')) {
                        // image_url = expense.expense_image_file
                        image_url2 = CLOUDFRONT_URL+expense.expense_image_key2
                    }else{
                        image_url2 = IMAGE_FULL_URL + expense.expense_image_file2
                    }
                }else{
                    image_url2 = IMAGE_NOPHOTO_URL
                }
            }


            // console.log('image_url=', image_url)
            // checkFormControls('cost_type_id', form_controls)
            // checkFormControls('invoice_no', form_controls)
            // checkFormControls('buy_from_company', form_controls)
            // checkFormControls('expense_amount_vat', form_controls)

            let invoice_text = checkFormControls('invoice_no', form_controls) ? <> เลขที่บิล : {expense.invoice_no} </> : null
            let buy_from_company_text = checkFormControls('buy_from_company', form_controls) ? <>  <br/> ซื้อจาก : {expense.buy_from_company} </> : null

            let pay_method = expense.pay_method === null || expense.pay_method === '' ? '-' : expense.pay_method

            return (
                <tr key={index}>
                    <td className="text-center">{index+1}</td>
                    <td className="text-center text-nowrap">{moment(expense.expense_date).locale('th').format('LL')}
                    <br/>
                    <p className="text-left mt-1">
                        {invoice_text}
                        {buy_from_company_text}
                    </p>

                    </td>
                    <td className="text-center">
                        { expense.pay_by !== null || expense.pay_by !== ''?  expense.pay_by : '-'}
                    </td>
                    <td className="text-center text-nowrap">{expense.branch_name}</td>
                    <td className="text-nowrap">
                       <span style={{width: 100}} > <strong> หมวดหมู่ : </strong> {expense.income_expense_item_name} </span> <br/>
                       <span style={{width: 100}} > <strong> รายละเอียด : </strong> {expense.expense_name} </span> <br/>
                       <span style={{width: 100}} > <strong> จ่ายโดย : </strong> {pay_method} </span>
                    </td>
                    <td className="text-center text-nowrap">

                        <img
                            src={image_url}

                            style={{'cursor' : 'pointer', 'width': '120px', 'height': 'auto'}}
                            alt="เอกสาร"
                            onClick={()=>this.onPreviewSelected(image_url)}
                        />
                    </td>
                    <td className="text-center">

                        <img
                            src={image_url2}

                            style={{'cursor' : 'pointer', 'width': '120px', 'height': 'auto'}}
                            alt="เอกสาร"
                            onClick={()=>this.onPreviewSelected(image_url2)}
                        />
                    </td>
                    <td className="text-center text-nowrap">
                        <NumberFormat
                            className={expense.expense_amount < 0 ? "text-danger" : ""}
                            value={expense.expense_amount}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={'฿'}
                        />
                      {
                        expense.include_vat === 1 ? (
                          <>
                           <br/>
                           ** รวม VAT
                          </>
                        ) : (expense.expense_amount_vat === 0 ? <><br/> ** NO VAT</>: <><br/> ** ไม่รวม VAT</>)
                      }
                    </td>
                    <td className="text-center">
                        <NumberFormat value={expense.expense_amount_vat}
                                      displayType={'text'}
                                      thousandSeparator={true}
                                      prefix={'฿'}/>
                    </td>
                    <td className="text-center text-nowrap">
                        {expense.fullname}
                        <br/>
                        {moment(expense.updated_at).locale('th').format('DD-MM-YYYY HH:mm')}
                    </td>
                    <td className="text-center">

                      {
                        checkGroupPermission(menuID, this.props.grouppermissions).modified ? (
                          <>
                            <button className="btn btn-sm btn-warning btn-md-width"
                                    style={{width: 100}}
                                    onClick={()=> {
                                      if (sell_status_check) {
                                        AlertError('ไม่สามารถแก้ไขค่าใช้จ่ายได้ เนื่องจากปิดการขายแล้ว');
                                        return
                                      }
                                      this.onOpenCarExpenseFormEdit(expense)
                                    }}
                            >
                              <i className="fa fa-pencil"/> แก้ไข
                            </button>
                            <br/>
                          </>
                        ) : null
                      }

                      {
                        checkGroupPermission(menuID, this.props.grouppermissions).approved ? (
                          <>
                            <button className="btn btn-sm btn-success btn-md-width  mt-2"
                                    style={{width: 100}}
                                    onClick={() => this.onOpenCarExpenseImage(expense)}
                            >
                              <i className="fa fa-file"/> แนบไฟล์
                            </button>
                            <br/>
                          </>
                        ) : null
                      }

                      {
                        checkGroupPermission(menuID, this.props.grouppermissions).deleted ? (
                          <button className="btn btn-sm btn-danger btn-md-width mt-2"
                                  style={{width: 100}}
                                  onClick={() => {
                                    if (sell_status_check) {
                                      AlertError('ไม่สามารถลบค่าใช้จ่ายได้ เนื่องจากปิดการขายแล้ว');
                                      return
                                    }
                                    this.onDeleteHandle(expense)
                                  }}
                          >
                            <i className="fa fa-remove"/> ลบ
                          </button>
                        ) : null
                      }

                    </td>
                </tr>
            )
        });


      let label = 'no found expense_type_id'
      if (expense_type_id === 1) {
        label = 'ค่าใช้จ่ายอื่นๆของรถ'
      } else if (expense_type_id === 4) {
        label = 'ค่าใช้จ่ายในการขาย'
      }

        if(this.props.car_expense_list.length === 0){
          car_expense_list = <tr>
            <td colSpan={11} className="text-center text-danger">
              ไม่พบรายการ{label}
            </td>
          </tr>
        }


        // ค่าใช้จ่ายอื่นๆของรถไม่รวม VAT &nbsp;&nbsp;
        return (
            <div className="card-block" style={styles.overflowY}>

              {
                sum_expense_cost === 0 ? null : (
                  <>
                    <h5 className="text-right">
                      {
                        expense_type_id === 1 ? '' : ''
                      }
                      {label}ไม่รวม VAT &nbsp;&nbsp;
                      <NumberFormat
                        value={sum_expense_cost.toFixed(2)}
                        displayType={'text'}
                        thousandSeparator={true}
                        //prefix={'฿'}
                      />
                      &nbsp;&nbsp; บาท
                    </h5>
                  </>
                )
              }

                {
                    sum_vat === 0 ? null : (
                        <>
                            <h5 className="text-right">
                                VAT {label} &nbsp;&nbsp;
                                <NumberFormat
                                    value={sum_vat.toFixed(2)}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    //prefix={'฿'}
                                />
                                &nbsp;&nbsp; บาท
                            </h5>
                        </>
                    )
                }

                {
                    sum_expense_vat === 0 ? null : (
                        <>
                            <h5 className="text-right text-success" style={{fontWeight:'bold'}}>
                              {label}รวม VAT &nbsp;&nbsp;
                                <NumberFormat
                                    value={sum_expense_vat.toFixed(2)}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    //prefix={'฿'}
                                />
                                &nbsp;&nbsp; บาท
                            </h5>
                        </>
                    )
                }

              {
                this.state.isOpenCarExpenseFormEdit ?
                  <CarExpenseFormEdit
                    isOpen={this.state.isOpenCarExpenseFormEdit}
                    expense={this.state.expense_selected}
                    car_id={this.state.expense_selected.car_id}
                    onToggle={this.onToggleCarExpenseFormEdit}
                    onReload={this.props.onReload}
                    company_setting={this.props.company_setting}
                    form_controls = {form_controls}
                    expense_type_id={this.props.expense_type_id}
                  />
                  : null
              }

              {
                this.state.isOpenCarExpenseImage ?
                    <CarExpenseFile
                      isOpen={this.state.isOpenCarExpenseImage}
                      expense={this.state.expense_selected}
                      onToggle={this.onToggleCarExpenseImage}
                      onReload={this.props.onReload}
                    />
                    : null
              }
                <table className="table table-striped">
                    <thead>
                    <tr>
                        <th className="text-center text-nowrap">#</th>
                        <th className="text-center text-nowrap">วันที่ใช้จ่าย</th>
                        <th className="text-center text-nowrap">ผู้เบิก</th>
                        <th className="text-center text-nowrap">สาขา</th>
                        <th className="text-center text-nowrap">รายละเอียด</th>
                        <th className="text-center text-nowrap">ไฟล์เอกสาร1</th>
                        <th className="text-center text-nowrap">ไฟล์เอกสาร2</th>
                        <th className="text-center text-nowrap">ยอดเงิน</th>
                        <th className="text-center text-nowrap">VAT</th>
                        <th className="text-center text-nowrap">ผู้บันทึก</th>
                        <th className="text-center text-nowrap">จัดการ</th>
                    </tr>
                    </thead>
                    <tbody>
                    {car_expense_list}
                    </tbody>
                </table>

                <Modal isOpen={this.state.preview_image_select} toggle={this.togglePreviewImage} className={'modal-lg '}>
                    <ModalBody>
                        <PreviewImage
                            imageUrl={this.state.preview_image_url}
                        />
                    </ModalBody>
                </Modal>
            </div>
        )
    }
}

const styles = {
    overflowY: {
        overflow: 'scroll',
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user,
    token: state.auth.token,
    isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps) (CarExpenseList)
