import React, { Component } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import {APIURL, CLOUDFRONT_URL, IMAGE_FULL_URL, IMAGE_NOPHOTO_URL, IMAGE_UPLOAD_ERROR} from '../../../config/config'
import axios from 'axios'

class CarExpenseFile extends Component{
  constructor (props) {
    super(props);
    // car_repair_file , car_repair_key
    this.state = {
      expense_image_file: '',
      expense_image_key: '',
      expense_image_file2: '',
      expense_image_key2: ''
    }

    this.saveCarImage = this.saveCarImage.bind(this);
    this.handleUploadFile = this.handleUploadFile.bind(this);

  }

  componentDidMount () {
    let expense = this.props.expense
    // expense_image_file , expense_image_key
    this.setState({
      expense_image_file: expense.expense_image_file === 'nophoto.png' ? IMAGE_NOPHOTO_URL: expense.expense_image_file,
      expense_image_key: expense.expense_image_key,
      expense_image_file2: expense.expense_image_file2 === 'nophoto.png' ? IMAGE_NOPHOTO_URL: expense.expense_image_file2,
      expense_image_key2: expense.expense_image_key2
    })
  }

  saveCarImage(e,urlImage){
    e.preventDefault();
    let a = document.createElement('a');
    a.href = urlImage;
    a.target = '_blank';
    a.download = urlImage;
    a.click();
    a.remove();
    return false;
  }

  handleUploadFile (event){
    const expense = this.props.expense
    const input_file_name = event.target.name
    // let slip_key = input_file_name === 'slip_image1' ? this.state.slip_key1 : this.state.slip_key2
    let expense_image_key = ''
    let expense_image_file = ''
    let expense_image_key2 = ''
    let expense_image_file2 = ''

    const that = this;
    const expense_id = expense.expense_id


    // input_file_name= slip_image2
    if (input_file_name === 'file_image1'){
      expense_image_key = this.state.expense_image_key === null ? '' : this.state.expense_image_key
      expense_image_file = this.state.expense_image_file === null ? '' : this.state.expense_image_file
      this.refs.img_file_image1.src='img/loading.gif'
      this.refs.img_file_image1.height=32
    } else if (input_file_name === 'file_image2') {
      expense_image_key2 = this.state.expense_image_key2 === null ? '' : this.state.expense_image_key2
      expense_image_file2 = this.state.expense_image_file2 === null ? '' : this.state.expense_image_file2
      this.refs.img_file_image2.src='img/loading.gif'
      this.refs.img_file_image2.height=32
    }

    // return

    const data = new FormData();
    data.append('uploads', event.target.files[0], event.target.files[0].name)
    data.append('expense_id', expense_id)
    data.append('expense_image_key', expense_image_key)
    data.append('expense_image_file', expense_image_file)
    data.append('input_file_name', input_file_name)

    // axios.put(APIURL+'/debtors_detail/slip/'+debtors_detail_id, data).then(function(res) {
    axios.put(APIURL+'/expense/image/'+expense_id, data).then(function(res) {
      const resData = res.data;
      const filename = resData.image;
      const image_key = resData.image_key;
      let image_url = ''

      if(resData.image === undefined) {
        image_url = IMAGE_UPLOAD_ERROR
      } else if (resData.image === 'uploaderror') {
        image_url = IMAGE_UPLOAD_ERROR
      } else {
        image_url = filename
      }

      if( input_file_name === 'file_image1'){
        that.setState({
          expense_image_file: image_url,
          expense_image_key: image_key,
        }, () =>  {
          // close && reload data
          that.props.onToggle()
          that.props.onReload()
        })
      } else if( input_file_name === 'file_image2'){
        that.setState({
          expense_image_file2: image_url,
          expense_image_key2: image_key,
        }, () =>  {
          // close && reload data
          that.props.onToggle()
          that.props.onReload()
        })
      }
    })

  }

  render () {
    const {
      width,
      expense
    } = this.props

    // let car_repair_id = car_repair.car_repair_id
    console.log(expense)
    let image_url = ''

    if(expense.expense_image_file === 'nophoto.png') {
      image_url = IMAGE_NOPHOTO_URL
    } else {
      // check https
      if (expense.expense_image_file !== null && expense.expense_image_file !== undefined) {
        if (expense.expense_image_file.includes('https')) {
          // image_url = expense.expense_image_file
          image_url = CLOUDFRONT_URL+expense.expense_image_key
        }else{
          image_url = IMAGE_FULL_URL + expense.expense_image_file
        }
      }else{
        image_url = IMAGE_NOPHOTO_URL
      }
    }

    let image_url2 = ''
    if(expense.expense_image_file2 === 'nophoto.png') {
      image_url2 = IMAGE_NOPHOTO_URL
    } else {
      if (expense.expense_image_file2 !== null && expense.expense_image_file2!== undefined) {
        if (expense.expense_image_file2.includes('https')) {
          // image_url = expense.expense_image_file
          image_url2 = CLOUDFRONT_URL+expense.expense_image_key2
        }else{
          image_url2 = IMAGE_FULL_URL + expense.expense_image_file2
        }
      }else{
        image_url2 = IMAGE_NOPHOTO_URL
      }
    }



    return (
      <Modal isOpen={this.props.isOpen} toggle={this.props.onToggle}
             className={'modal-lg '}
             style={width<=980 ?{maxWidth:800}:{maxWidth:1200}}
      >
        <ModalHeader toggle={this.props.onToggle}>แนบไฟล์เอกสาร</ModalHeader>
        <ModalBody>
          <div className="row p-5">

            <div className="form-group col-sm-1">
            </div>

            <div className="form-group col-sm-5">
              <label htmlFor="name">1) แนบเอกสาร 1
                &nbsp;
                <a rel="noopener noreferrer"
                   href={image_url}
                   target="_blank"
                   onClick={(e)=>this.saveCarImage(e, image_url)}
                   className={image_url===IMAGE_NOPHOTO_URL? 'hidden' : ''}>
                  <span>donwload</span>
                </a>
              </label>
              <div style={styles.fileInputWrapper} >

                <button style={styles.btnFileInput}>
                  <img src={image_url}
                       style={styles.btnFileInputImage}
                       ref="img_file_image1"
                       alt="เอกสาร 1"
                  />
                </button>
                <input
                  style={styles.fileInput}
                  type="file"
                  name="file_image1"
                  onChange={this.handleUploadFile}
                />
              </div>
            </div>

            <div className="form-group col-sm-5">
              <label htmlFor="name">2) แนบเอกสาร 2
                &nbsp;
                <a rel="noopener noreferrer"
                   href={image_url2}
                   target="_blank"
                   onClick={(e)=>this.saveCarImage(e, image_url2)}
                   className={image_url2===IMAGE_NOPHOTO_URL? 'hidden' : ''}>
                  <span>Download</span>
                </a>
              </label>

              <div style={styles.fileInputWrapper} >
                <button style={styles.btnFileInput}>
                  <img src={image_url2}
                       style={styles.btnFileInputImage}
                       ref="img_file_image2"
                       alt="เอกสาร 2"
                  />
                </button>
                <input
                    style={styles.fileInput}
                    type="file"
                    name="file_image2"
                    onChange={this.handleUploadFile}
                />
              </div>

            </div>

          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={this.props.onToggle}>ยกเลิก</Button>
        </ModalFooter>
      </Modal>
    )
  }
}


const styles = {
  bottomLine: {
    borderBottomColor: '#ccc',
    borderBottomStyle: 'solid',
    borderWidth: 0.5,
  },
  fileInputWrapper: {
    position: 'relative',
    overflow: 'hidden',
    height: 180,
    width: 200,
  },
  btnFileInput: {
    height: 180,
    width: 200,
    display: 'inline-block',
    backgroundColor: "#D2DDE2",
    backgroundImage: "linear-gradient(to bottom, #D2DDE2, #FCF8FF)",
    backgroundRepeat: "repeat-x",
    color: "#ffffff",
    textShadow: "0 -1px 0 rgba(0, 0, 0, 0.25)"
  },
  btnFileInputImage:{
    position: 'absolute',
    top: 0,
    left: 0,
    height: 180,
    width: 200,
  },
  fileInput: {
    position: 'absolute',
    top: 0,
    right: 0,
    opacity: 0,
    height: 180,
    width: 200,
    cursor: 'pointer'
  }
}

export default CarExpenseFile
