import React, { Component } from 'react'
import {checkGroupPermission} from "../../../services/grouppermission";
import {IMAGE_FULL_URL, IMAGE_NOPHOTO_URL} from "../../../config/config";
import {Modal, ModalBody} from "reactstrap";
import PreviewImage from "../../../views/PreviewImage";

class CustomerFindCarListRow extends Component {
    constructor (props){
        super(props)

        this.state = {
            preview_image_url: '',
            preview_image_select: false,
        }
        this.togglePreviewImage = this.togglePreviewImage.bind(this)
        this.onPreviewSelected = this.onPreviewSelected.bind(this);
    }

    togglePreviewImage() {
        this.setState({
            preview_image_select: !this.state.preview_image_select
        });
    }

    onPreviewSelected(url){
        this.setState({
            preview_image_url: url,
            preview_image_select: true
        });
    }

  render () {
      let customerList;

      if (this.props.customer_list.length === 0) {
          customerList = <tr>
              <td colSpan={5} className="text-center"><span className="text-danger">ไม่พบข้อมูล</span></td>
          </tr>
      } else {

         customerList = this.props.customer_list.map((customer, index) => {
             let image_url = ''
             if( customer.customer_file1 === 'nophoto.png') {
                 image_url = IMAGE_NOPHOTO_URL
             } else {
                 // check https
                 if (customer.customer_file1 !== null) {
                     if (customer.customer_file1.includes('https')) {
                         image_url = customer.customer_file1
                     }else{
                         image_url = IMAGE_FULL_URL + customer.customer_file1
                     }
                 }else{
                     image_url = IMAGE_NOPHOTO_URL
                 }
             }

          return (
              <tr key={index}>
                  <td className="text-center">{index + 1}</td>
                  <td>
                      ชื่อ-นามสกุล : {customer.customer_name} {customer.customer_lastname} <br/>
                      ที่อยู่ : {customer.customer_address} <br/>
                      มือถือ : {customer.customer_mobile} <br/>
                      แหล่งที่มา : {customer.source_customer} <br/>
                  </td>
                  <td>
                      ยี่ห้อ : {customer.car_brand_name} รุ่น : {customer.car_model_name}<br/>
                      เกียร์ : {customer.car_gear_name} <br/>
                      สี : {customer.car_color_name} &nbsp;
                      ปีรถเริ่ม : {customer.car_year_start_name} <br/>
                      ปีรถสิ้นสุด : {customer.car_year_final_name} <br/>
                  </td>

                  <td className="text-center text-nowrap">
                      <img
                          src={image_url}
                          style={{'cursor' : 'pointer', 'width': '120px', 'height': '100px'}}
                          onClick={()=>this.onPreviewSelected(image_url)}
                          alt="เอกสาร"
                      />
                  </td>

                  <td className="text-center">
                      {
                          checkGroupPermission(601, this.props.grouppermissions).modified ? (
                              <>
                                  <button className="btn btn-sm btn-warning btn-md-width mb-2" style={{width :100}}
                                          onClick={this.props.customerCallbacks.edit.bind(null, customer)}
                                  >
                                      <i className="fa fa-edit"/> แก้ไข
                                  </button>
                                  <br/>
                              </>
                          ) : null
                      }

                      {
                          checkGroupPermission(601, this.props.grouppermissions).printed ? (
                              <>
                                  <button className="btn btn-sm btn-success btn-md-width mb-2" style={{width :100}}
                                          onClick={this.props.customerCallbacks.uploadFile.bind(null, customer)}
                                  >
                                      <i className="fa fa-file"/> แนบไฟล์
                                  </button>
                                  <br/>
                              </>
                          ) : null
                      }

                      {
                          checkGroupPermission(601, this.props.grouppermissions).deleted ? (
                              <button className="btn btn-sm btn-danger btn-md-width" style={{width :100}}
                                      onClick={this.props.customerCallbacks.delete.bind(null, customer)}
                              >
                                  <i className="fa fa-remove"/> ลบ</button>
                          ) : null
                      }

                      {
                          checkGroupPermission(601, this.props.grouppermissions).modified === 0
                          && checkGroupPermission(601, this.props.grouppermissions).deleted === 0
                              ? <i className="icon-close"/> : null
                      }

                  </td>
              </tr>
          )
      });
  }

    return (
      <div className="card-block">
        <table className="table table-striped">
          <thead>
          <tr>
            <th className="text-center">#</th>
            <th className="text-center">ข้อมูลลูกค้า</th>
            <th className="text-center">ข้อมูลรถที่หา</th>
            <th className="text-center">ไฟล์ภาพ</th>
            <th className="text-center" style={{width: 100}}>จัดการ</th>
          </tr>
          </thead>
          <tbody>
           {customerList}
          </tbody>
        </table>

          <Modal isOpen={this.state.preview_image_select} toggle={this.togglePreviewImage} className={'modal-lg '}>
              <ModalBody>
                  <PreviewImage
                      imageUrl={this.state.preview_image_url}
                  />
              </ModalBody>
          </Modal>
      </div>
    )
  }
}

export default CustomerFindCarListRow