// ExpenseWithdrawMoneyForm

import React, { Component } from 'react';
import axios from 'axios';
import {APIURL, PORT} from '../../config/config';
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import 'moment/locale/th';
import NumberFormat from 'react-number-format';
import SelectSearch from "react-select-search";
import {AlertSuccess, AlertWarning} from "../Alert/Alert";
import {ThaiDatePicker} from "thaidatepicker-react";
import {format} from "date-fns";
import BDatePicker from '../BDatePicker'
import Switch from 'react-switch'
import {checkFormControls} from "../../services/grouppermission";
import {Modal, ModalBody} from "reactstrap";
moment.locale('th');

class ExpenseWithdrawMoneyForm extends Component {
    constructor (props) {
        super(props);

        this.state = {
            lists: [],
            load_data: true,
            danger: false,
            expense:'',
            branch:[],
            expense_type:[],
            income_expense_item:[],
            expense_amount:0,
            expense_amount_vat:0,
            startDateExpense:  new Date(), // moment().format('YYYY-MM-DD'),
            expense_name:'',
            pay_by: '',
            expense_image:'',
            user: {},
            w_expense_image_file: false,
            //selectedItem:'',
            select_income_expense_item_id: 0,

            invoice_no : '',
            buy_from_company : '',
            vatPercent: null,
            company_setting: null,
            is_vat: null,
            expense_date: format(new Date(), 'yyyy-MM-dd'),
            include_vat: 0,
            cost_types: [],
            cost_type_id: PORT === 9043 ? '1' : '0',
            branch_id: '',
            expense_id: 0
        }
        this.handleDateExpense = this.handleDateExpense.bind(this);
        this.convertBBtoCC = this.convertBBtoCC.bind(this);
        this.onMaskedDateExpense = this.onMaskedDateExpense.bind(this);
        this.handleExpenseNameChange = this.handleExpenseNameChange.bind(this);
        this.handleOnSubmitExpense = this.handleOnSubmitExpense.bind(this);
        this.handleInvoiceNoChange = this.handleInvoiceNoChange.bind(this);
        this.handleBuyFromCompanyChange = this.handleBuyFromCompanyChange.bind(this);
        this.loadCompanySetting = this.loadCompanySetting.bind(this);
        this.loadCostTypeData = this.loadCostTypeData.bind(this)
    }

    loadCostTypeData () {
        axios.get(APIURL + '/cost_type')
            .then(res => {
                this.setState({cost_types: res.data})
            }).catch(error => {
            this.setState({cost_types: []})
        })
    }

    handleOnSubmitExpense(event) {
        event.persist()
        event.preventDefault()
        let that = this ;
        // let expense_date = this.state.startDateExpense
        // let expense_date =  event.target.expense_date_text.value
        let expense_date = format(new Date(this.state.expense_date), 'yyyy-MM-dd')
        let branch_id = event.target.branch_id.value
        // let income_expense_item_id = event.target.income_expense_item_id.value
        let income_expense_item_id = this.state.select_income_expense_item_id
        let expense_name = event.target.expense_name.value
        let expense_amount = event.target.expense_amount.value
        // let expense_image_file = event.target.expense_image_file.value
        let pay_by = this.state.pay_by
        let include_vat = this.state.include_vat
        let expense_amount_vat = this.state.expense_amount_vat
        let expense_type_id = 5
        /* let invoice_no = event.target.invoice_no.value
        let buy_from_company = event.target.buy_from_company.value
        let cost_type_id = event.target.cost_type_id.value */

        let invoice_no = ''
        let buy_from_company = ''
        let cost_type_id = '0'

        if(expense_amount===''){
            AlertWarning('กรุณากรอกจำนวนเงิน')
            // this.refs.expense_amount.focus()
            return ;
        }

        if(income_expense_item_id===''){
            AlertWarning('กรุณาเลือกหมวดหมู่การเบิก')
            // this.refs.income_expense_item_id.focus()
            return ;
        }

        if(branch_id===''){
            AlertWarning('กรุณาเลือกสาขา')
            // this.refs.branch_id.focus()
            return ;
        }

        /* try {
          if(event.target.expense_image_file.files[0].name === undefined){
            this.setState({
              w_expense_image_file: false
            });
          }
        }catch (ex){
          this.setState({
            w_expense_image_file: true
          });
          return;
        } */


        if(event.target.expense_image_file.files.length === 0) { // nofile
            let data = {
                expense_id: this.state.expense_id,
                expense_date: expense_date,
                branch_id: branch_id,
                income_expense_item_id: income_expense_item_id,
                expense_name: expense_name,
                expense_amount: expense_amount,
                expense_type_id: expense_type_id,
                user_created: this.state.user.user,
                user_updated: this.state.user.user,
                expense_image_file: 'nophoto.png',
                pay_by: pay_by,
                expense_amount_vat: expense_amount_vat,
                invoice_no: invoice_no,
                buy_from_company: buy_from_company,
                include_vat: include_vat,
                cost_type_id: cost_type_id
            };


            let form_status = this.props.form_status

            if (form_status === 'add') {
                axios.post(APIURL + '/expense/nofile/', data)
                    .then(() => {
                        this.props.onToggle(true);
                    })
                    .catch(function (error) {
                        console.log('error:', error)
                    });
            } else if (form_status === 'edit') {

                axios.post(APIURL + '/expense/edit/', data)
                    .then(res => {

                        if (res.data === 'success') {
                            AlertSuccess('แก้ไขข้อมูลเรียบร้อยแล้ว');
                            this.props.onToggle(true);
                        }

                    })
                    .catch(function (error) {
                        console.log('error:', error)
                    })
            }

        } else {

            // no แนบไฟล์
        /*    let filename = event.target.expense_image_file.files[0].name;
            //alert (filename)
            //return;

            if (!(/\.(gif|jpg|jpeg|tiff|png)$/i).test(filename)) {
                //alert(event.target.car_repair_file.files[0].type);
                this.setState({
                    w_expense_image_file: true
                });
                return;
            }

            const data = new FormData();
            data.append('file', event.target.expense_image_file.files[0], event.target.expense_image_file.files[0].name);
            data.append('expense_date', expense_date);
            data.append('branch_id', branch_id);
            data.append('income_expense_item_id', income_expense_item_id);
            data.append('expense_name', expense_name);
            data.append('expense_amount', expense_amount);
            data.append('expense_type_id', expense_type_id);
            data.append('user_created', this.state.user.user);
            data.append('user_updated', this.state.user.user);
            data.append('pay_by', pay_by);
            data.append('expense_amount_vat', expense_amount_vat);
            data.append('invoice_no', invoice_no);
            data.append('buy_from_company', buy_from_company);
            data.append('include_vat', include_vat);
            data.append('cost_type_id', cost_type_id);
            // include_vat: include_vat

            axios.post(APIURL + '/expense/s3', data)
                .then(function (response) {
                    that.props.onToggle(true);
                })
                .catch(function (error) {
                    console.log('error: ', error)
                    //alert("error"+error)
                }) */
        }
    }


    handleDateExpense(date) {
        this.setState({
            startDateExpense: new Date(date)
        });
    }

    onMaskedDateExpense(e){
        let buddhist_date = e.target.value ;
        //alert('buddhist_date='+buddhist_date);
        if(buddhist_date.length===10){
            let cc_date = this.convertBBtoCC(buddhist_date)
            this.handleDateExpense(cc_date)
        }
    }

    convertBBtoCC(buddhist_date){ //dd-mm-yyyy to yyyy-mm-dd
        let date_arr=buddhist_date.split('-');
        let dd = date_arr[0];
        let mm = date_arr[1];
        let yyyy = date_arr[2]-543;
        let cc_date= yyyy+'-'+mm+'-'+dd ;
        if(moment(cc_date, 'YYYY-MM-DD', true).isValid()){
            //alert(cc_date);
            return cc_date ;
        }
        return moment() ;
    }

    loadCompanySetting() {
        axios.get(APIURL + '/company_setting/1').then(res => {
            const data = res.data;
            const company_setting = data.company_setting;
            const is_vat = company_setting.is_vat;
            const vatPercent = is_vat === 1 ? company_setting.vat/100.0 : 0;

            this.setState({
                company_setting: company_setting,
                is_vat,
                vatPercent,
            });
        })
    }


    handleIncomeExpenseItemChange (event) {
        // let income_expense_item_id = event.target.value
        let income_expense_item_name = event.target.options[event.target.selectedIndex].text
        let that = this ;

        that.setState({
            expense_name: income_expense_item_name,
            // income_expense_item_id: income_expense_item_id
        })
    }

    handleExpenseNameChange(){
        let expense_name = this.refs.expense_name.value
        this.setState({
            expense_name : expense_name
        });
    }

    handleInvoiceNoChange(){
        let invoice_no = this.refs.invoice_no.value
        this.setState({
            invoice_no : invoice_no
        });
    }

    handleBuyFromCompanyChange(){
        let buy_from_company = this.refs.buy_from_company.value
        this.setState({
            buy_from_company : buy_from_company
        });
    }

    loadData () {
        axios.get(APIURL + '/expense/')
            .then(res => {
                //alert("res"+res);
                this.setState({lists: res.data, })
            }).catch(error => {
            //alert("error"+error.message);
            this.setState({lists: []})
        })
    }

    loadBranchData () {
        axios.get(APIURL + '/branch/')
            .then(res => {
                let branchs = res.data
                this.setState({branch: branchs}, () => {
                    let form_status = this.props.form_status
                    if (form_status === 'add') {
                        if(PORT === 9043){
                            // this.refs.branch_id.value = 2
                            this.setState({
                                branch_id: 2
                            })
                        }else{
                            let branch_id = branchs.length > 0 ? branchs[0].branch_id : 0
                            // this.refs.branch_id.value = branch_id
                            this.setState({
                                branch_id: branch_id
                            })
                        }
                    }
                })
            }).catch(error => {
            this.setState({branch: []})
        })
    }

    loadExpenseTypeData () {
        axios.get(APIURL + '/expense_type/')
            .then(res => {
                this.setState({expense_type: res.data})
            }).catch(error => {
            this.setState({expense_type: []})
        })
    }

    loadIncomeExpenseItemData () {
        axios.get(APIURL + '/income_expense_item/get/withdraw')
            .then(res => {
                this.setState({income_expense_item: res.data})
            }).catch(error => {
            this.setState({income_expense_item: []})
        })
    }

    componentDidMount() {
        this.loadData()
        this.loadBranchData()
        this.loadExpenseTypeData()
        this.loadIncomeExpenseItemData()
        this.loadCompanySetting()
       // this.loadCostTypeData()

        let expense = this.props.expense
        let form_status = this.props.form_status
        if (form_status === 'edit' && expense !== null) {

            this.setState({
                expense_id: expense.expense_id,
                branch_id: expense.branch_id,
                expense_date: expense.expense_date,
                select_income_expense_item_id: expense.income_expense_item_id,
                expense_name: expense.expense_name,
                expense_amount: expense.expense_amount,

            })
        }


    }

    componentWillMount () {
        this.setState({
            user: JSON.parse(this.props.user)
        });
    }

    render(){
        // const { history,user } = this.props
        const {
            branch,
            cost_types
        } = this.state;

        const form_controls = this.props.form_controls

        let branch_list = branch.map((branch,index)=>{
            return(
                <option key={index} value={branch.branch_id}>{branch.branch_name}</option>
            )
        })

        /*let income_expense_item_list = this.state.income_expense_item.map((income_expense_item,index)=>{
              return(
                  <option key={index} value={income_expense_item.income_expense_item_id}>{income_expense_item.income_expense_item_name}</option>
              )
          })*/

        /*let cost_type_list = cost_types.map((cost_type,index)=>{
            return(
                <option key={index} value={cost_type.cost_type_id}>{cost_type.cost_type_name}</option>
            )
        })*/


        let form_status = this.props.form_status


        return (
            <Modal isOpen={this.props.toggle_expense}
                   toggle={() => this.props.onToggle(false)}
                   className={'modal-lg'}
                   style={{maxWidth:960}}
            >
                <ModalBody>
                    <div className={form_status ==='add' ? "card card-accent-primary" : "card card-accent-warning"} >
                <form action="" method="post"
                      onSubmit={this.handleOnSubmitExpense.bind(this)}
                >
                    <div className="card-header">
                        <strong className="text-title">
                            {form_status ==='add' ? 'เพิ่มข้อมูลการเบิก' : 'แก้ไขข้อมูลการเบิก'}
                            </strong>
                    </div>

                    <div className="card-block">
                        <div className="row">

                            <div className="form-group col-sm-6">
                                <label htmlFor="branch">สาขา</label>
                                <select className="form-control"
                                        id="branch_id"
                                        name="branch_id"
                                        value={this.state.branch_id}
                                        onChange={(e)=> {
                                            let val = e.target.value
                                            this.setState({
                                                branch_id: val
                                            })
                                        }}
                                >
                                    <option value=''>--เลือกสาขา-- </option>
                                    {branch_list}
                                </select>
                            </div>

                            <div className="form-group col-sm-6">

                                <BDatePicker
                                    isDateFill={this.props.isDateFill}
                                    name={'expense_date'}
                                    value={this.state.expense_date}
                                    label={'วันที่เบิก '}
                                    onUpdateDate={(buddhist_date, christ_date) => {
                                        this.setState({
                                            expense_date: christ_date
                                        })
                                    }}
                                />

                            </div>

                            <div
                                className={'form-group col-sm-6'}
                            >
                                <label htmlFor="income_expense_item">หมวดการเบิก</label>

                                <SelectSearch
                                    ref="income_expense_item_id"
                                    options={this.state.income_expense_item}
                                    value={this.state.select_income_expense_item_id}
                                    search={true}
                                    name="income_expense_item_id"
                                    placeholder="เลือกหมวดการเบิก"
                                    onChange={(val) => {
                                        this.setState({
                                            select_income_expense_item_id: val
                                        })
                                    }}
                                />
                            </div>

                            {/*<div
                                className={checkFormControls('cost_type_id', form_controls) ? "form-group col-sm-3" : 'hidden'}
                            >
                                <label htmlFor="cost_type_id">ประเภทต้นทุน</label>
                                <select
                                    className="form-control"
                                    name="cost_type_id"
                                    value={this.state.cost_type_id}
                                    onChange={(e) => {
                                        let val = e.target.value
                                        this.setState({
                                            cost_type_id: val
                                        })
                                    }}
                                >
                                    {cost_type_list}
                                </select>
                            </div>*/}

                            <div className="form-group col-sm-6">
                                <label htmlFor="expense_name">รายละเอียดการเบิก (พิมพ์เพิ่มเติมได้)</label>
                                <input type="text"
                                       className="form-control"
                                       id="expense_name"
                                       ref="expense_name"
                                       value={this.state.expense_name}
                                       placeholder=""
                                       onChange={this.handleExpenseNameChange}
                                />
                            </div>

                            {/*<div
                                className={checkFormControls('invoice_no', form_controls) ? "form-group col-sm-6" : 'hidden'}
                            >
                                <label htmlFor="invoice_no">เลขที่บิล/ใบสั่งซื้อ</label>
                                <input type="text"
                                       className="form-control"
                                       id="invoice_no"
                                       ref="invoice_no"
                                       value={this.state.invoice_no}
                                       placeholder=""
                                       onChange={this.handleInvoiceNoChange}
                                />
                            </div>*/}

                            {/*<div
                                className={checkFormControls('buy_from_company', form_controls) ? "form-group col-sm-6" : 'hidden'}
                            >
                                <label htmlFor="buy_from_company">บริษัทคู่ค้า</label>
                                <input type="text"
                                       className="form-control"
                                       id="buy_from_company"
                                       ref="buy_from_company"
                                       value={this.state.buy_from_company}
                                       placeholder=""
                                       onChange={this.handleBuyFromCompanyChange}
                                />
                            </div>*/}

                            <div className="form-group col-sm-6">
                                <label htmlFor="expense_amount" className="form-inline">
                                    ยอดเบิก &nbsp;
                                    {
                                       // this.state.include_vat === 1 ? '*รวม vat': '*ไม่รวม vat'
                                    }
                                    {/*&nbsp;
                                    <div style={{marginBottom: -2}}>
                                        <Switch height = {20} width ={48}
                                                onChange={(checked) => {
                                                    if (checked === false) {
                                                        this.setState({include_vat: 0})
                                                    } else {
                                                        this.setState({include_vat: 1})
                                                    }
                                                }}
                                                checked={this.state.include_vat === 1}
                                        />
                                    </div>*/}
                                </label>
                                <input
                                    type="text"
                                    className="form-control hidden"
                                    name="expense_amount"
                                    placeholder=""
                                    value={this.state.expense_amount}
                                    onChange={() => {}}
                                />
                                <NumberFormat
                                    className="form-control"
                                    thousandSeparator={true}
                                    prefix={'฿'}
                                    placeholder="ยอดเบิก"
                                    min={0}
                                    max={1000000000}
                                    step={1000}
                                    size={10}
                                    allowNegative={false}
                                    value={this.state.expense_amount}
                                    onValueChange = {(values) => {
                                        const {value} = values;
                                        this.setState({expense_amount: value})
                                    }}
                                />
                            </div>

                            {/*<div
                                className={checkFormControls('expense_amount_vat', form_controls) ? "form-group col-sm-4" : 'hidden'}
                            >
                                <label htmlFor="expense_amount_vat">VAT(ถ้ามี)&nbsp;</label>
                                <button type="button" onClick={() => {

                                    if (this.state.include_vat === 0) {
                                        let expense_amount = this.state.expense_amount === '' ? 0 : Number(this.state.expense_amount);
                                        let vat = expense_amount * this.state.vatPercent;
                                        let vat_fix = parseFloat(String(vat)).toFixed(2);

                                        this.setState({
                                            expense_amount_vat: vat_fix
                                        });

                                    } else if (this.state.include_vat === 1) {
                                        let expense_amount = this.state.expense_amount === '' ? 0 : Number(this.state.expense_amount);
                                        let vatNumber = Number(this.state.company_setting.vat) // = 7
                                        let vatDivide = 100 + vatNumber // 107
                                        let vatAmount = expense_amount * (vatNumber / vatDivide)
                                        let vat_fix = parseFloat(String(vatAmount)).toFixed(2);

                                        this.setState({
                                            expense_amount_vat: vat_fix
                                        });
                                    }

                                }} >
                                    {this.state.include_vat === 1 ? 'ถอด VAT' : 'คำนวณ VAT'}
                                </button>
                                <NumberFormat
                                    className="form-control"
                                    style={{marginTop: -3}}
                                    thousandSeparator={true}
                                    prefix={'฿'}
                                    placeholder="VAT ค่าใช้จ่าย"
                                    min={0}
                                    max={1000000000}
                                    step={1000}
                                    size={10}
                                    allowNegative={false}
                                    value={this.state.expense_amount_vat}
                                    onValueChange = {(values) => {
                                        const {value} = values;
                                        this.setState({expense_amount_vat: value})
                                    }}
                                />
                            </div>*/}

                            {/*<div className="form-group col-sm-4">
                                <label htmlFor="pay_by">ผู้เบิก</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="pay_by"
                                    placeholder=""
                                    value={this.state.pay_by}
                                    onChange={(e)=>{
                                        this.setState({pay_by: e.target.value })
                                    }}
                                />
                            </div>*/}

                            <div className="form-group col-md-12 hidden">
                                <label htmlFor="expense_image_file">เอกสารแนบ </label>
                                <input type="file"
                                       id="expense_image_file"
                                       name="expense_image_file"
                                       accept="image/*"
                                       className="form-control"
                                       onChange={(e)=>{
                                           const { target } = e
                                           if(target.value.length > 0){
                                               this.setState({
                                                   w_expense_image_file: false
                                               });
                                           } else {
                                               //target.reset();
                                               this.setState({
                                                   w_expense_image_file: true
                                               });
                                           }

                                       }}
                                />
                                { this.state.w_expense_image_file ? <span  className="help-block text-danger">กรุณาเลือกเอกสารแนบ</span>: null }

                            </div>

                        </div>
                    </div>

                    <div className="card-footer text-right">
                        <button type="reset"
                                onClick={(e) => this.props.onToggle(false)}
                                className="btn btn-sm btn-danger mr-2">
                            <i className="fa fa-refresh"/> ยกเลิก
                        </button>
                        <button type="submit"
                                className={form_status === 'add' ? "btn btn-sm btn-primary" : "btn btn-sm btn-warning" } >

                            {
                                form_status === 'add' ? <> <i className="fa fa-save"/> บันทึก </> : <> <i className="fa fa-edit"/> แก้ไข </>
                            }
                        </button>
                        {/*<input type="hidden" id="car_id" name="car_id" value={this.state.car.car_id}/>*/}
                    </div>

                </form>
            </div>
                </ModalBody>
            </Modal>
                    )

    }
}

/*const styles = {
    bottomLine: {
        borderBottomColor: '#ccc',
        borderBottomStyle: 'solid',
        borderWidth: 0.5,
    }
}*/

const mapStateToProps = (state) => ({
    user: state.auth.user,
    token: state.auth.token,
    isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps)(ExpenseWithdrawMoneyForm);
