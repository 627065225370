import React, { Component } from 'react';
import MaskedInput from 'react-maskedinput';
// import moment from 'moment'
// import {DatePicker as DatePickerThai} from "react-date-picker";
import { AlertError, AlertWarning } from './Alert/Alert';
import { DateUtil } from '../utils/dateUtil';
import DatePicker from 'react-datepicker';
import { format } from "date-fns";
// import { ThaiDatePicker } from 'thaidatepicker-react'
// import '../css/thai-date-picker.css';

class BDatePicker extends Component {
  constructor (props) {
    super(props);
    this.state = {

    }

    this.onMaskedChange = this.onMaskedChange.bind(this);
    this.convertCCToBB = this.convertCCToBB.bind(this);
  }

  onMaskedChange(e) {
      let buddhist_date = e.target.value

      if (buddhist_date === '') {
        this.props.onUpdateDate(null, null)
        return
      }

      if (!buddhist_date.includes('_')) {
        let date_arr = buddhist_date.split('-')
        let yyyyCheck = Number(date_arr[2])
        let checkYear = Number(String(yyyyCheck).substr(0, 2))

        if ( checkYear < 25) {
          AlertWarning('กรุณากรอกปี เป็น พ.ศ.');
          return
        }

        try {
          let dd = date_arr[0]
          let mm = date_arr[1]
          let yyyy = date_arr[2] - 543

          let validDate = DateUtil.isValidDate(dd, mm, yyyy)

          if(validDate.isValid){
            let christ_date = validDate.formatDate
            // send update data to parent
            this.props.onUpdateDate(buddhist_date, christ_date)
          }
        } catch (e) {
          AlertError('วันที่ไม่ถูกต้องกรุณาตรวจสอบ')
          // this.props.onUpdateDate(null, null)
        }
      }
  }

  convertCCToBB (buddhist_date) {
    // yyyy-mm-dd to dd-mm-yyyy พ.ศ.
    let date_arr = buddhist_date.split('-')
    let yyyy = Number(date_arr[0]) + 543
    let mm = date_arr[1]
    let dd = date_arr[2]

     // let cc_date = yyyy + '-' + mm + '-' + dd
     return dd+'-'+mm+'-'+yyyy
  }

  /*handleDateNew() {

  }*/

  render () {
    // inputType = fill, picker
    let isDateFill = this.props.isDateFill === undefined ? 0 : this.props.isDateFill
    let name = this.props.name
    let label = this.props.label
    let value = this.props.value
    let valueThai = null

    if (isDateFill ===0) {
      value = value === null || value === '' ? null : new Date(value)
    } else {
      valueThai = value === null || value === '' ? null : this.convertCCToBB(value)
    }

    if(isDateFill === 1){
      return <>
        {label === '' ? null :  <label htmlFor={name}>{label} (วัน-เดือน-ปี พ.ศ.)</label> }
        <MaskedInput
          mask="11-11-1111"
          name={name}
          value={valueThai}
          placeholder="dd-mm-yyyy"
          onChange={this.onMaskedChange}
          className="form-control"
        />
      </>
    } else {
      return (
        <>
          {label === '' ? null : <> <label htmlFor={name}>{label} (ว-ด-ป ค.ศ.)</label> <br/> </> }

          <DatePicker
            type="text"
            className="form-control col-sm-12"
            name={name}
            placeholder="dd-mm-yyyy"
            selected={value}
            onChange={(date) => {
              // console.log('date=', date)
              if (date === null) {
                this.props.onUpdateDate(null, date)
              } else {
                let val_date = format(new Date(date), 'yyyy-MM-dd')
                this.props.onUpdateDate(null, val_date)
              }

            }}
            dateFormat="dd-MM-yyyy"
          />
        </>
      )
    }
  }
}

export default BDatePicker
