import React, { Component } from 'react'
import { APIURL } from '../../config/config'
import axios from 'axios'
import { AlertSuccess } from '../Alert/Alert'
import Switch from 'react-switch'
import Swal from 'sweetalert2'

class SettingPromotion extends Component{
  constructor (props) {
    super(props);

    this.state = {
      actionType: "บันทึก",
      promotion_list: [],
      promotion_id: '',
      promotion_name: '',
      promotion_order: '',
    }

    this.loadPromotionData = this.loadPromotionData.bind(this);
    this.savePromotion = this.savePromotion.bind(this);
    this.setEditForm = this.setEditForm.bind(this);
    this.clearForm = this.clearForm.bind(this);
    this.onChangeSwitch = this.onChangeSwitch.bind(this);
    this.deletePromotion = this.deletePromotion.bind(this);

  }

  componentDidMount () {
    this.loadPromotionData()
  }

  loadPromotionData () {
    let url = APIURL+'/promotion'
    axios.get(url).then(res => {
      this.setState({
        promotion_list: res.data
      })
    })
  }

  deletePromotion(promotion){
    let promotion_id = promotion.promotion_id
    let promotion_name = promotion.promotion_name
    //let promotion_order = promotion.promotion_order
    //let username = this.props.username
    let data = {
      promotion_id: promotion_id,
      promotion_status: 'delete'
      //username: username
    }

    Swal.fire({
      title: 'ยืนยันการลบ '+promotion_name+' ?',
      icon: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
    }).then((result) => {
      if (result.value) {
        let url = APIURL+'/promotion/status'
        axios.post(url, data).then(res => {
          if(res.data === 'success') {
            AlertSuccess('ลบข้อมูลแล้ว')
            this.loadPromotionData()
          }
        })
      }
    });

  }

  savePromotion() {
    //let username = this.props.username
    let actionType = this.state.actionType
    let promotion_name = this.state.promotion_name
    let promotion_order = this.state.promotion_order

    let data= {
      promotion_name,
      promotion_order
      //username
    }

    if(actionType === "บันทึก") {
      let url = APIURL+'/promotion'
      axios.post(url, data).then(res => {
        if(res.data === 'success') {
          AlertSuccess('บันทึกข้อมูลแล้ว')
          this.clearForm()
          this.loadPromotionData()
        }
      })
    } else if (actionType === "แก้ไข") {
      let promotion_id = this.state.promotion_id
      data = {...data, promotion_id: promotion_id }
      let url = APIURL+'/promotion/'+promotion_id
      axios.put(url, data).then(res => {
        if(res.data === 'success') {
          AlertSuccess('แก้ไขข้อมูลแล้ว')
          this.clearForm()
          this.loadPromotionData()
        }
      })
    }
  }

  setEditForm(promotion) {
    this.setState({
      actionType: 'แก้ไข',
      promotion_name: promotion.promotion_name,
      promotion_order: promotion.promotion_order,
      promotion_id: promotion.promotion_id,
    })
  }

  clearForm() {
    this.setState({
      actionType: "บันทึก",
      promotion_name: '',
      promotion_order: '',
      promotion_id: '',
    })
  }

  onChangeSwitch(checked, promotion){
    //let username = this.props.username
    let item_update = promotion
    let promotions = this.state.promotion_list
    let item_index = this.state.promotion_list.findIndex(data => data.promotion_id=== promotion.promotion_id)

    if(checked === false){
      item_update.promotion_status = 'hidden'
    } else {
      item_update.promotion_status = 'show'
    }

    let data = {
      promotion_id: item_update.promotion_id,
      promotion_status: item_update.promotion_status
      //username: username
    }

    let url = APIURL+'/promotion/status'
      axios.post(url, data).then(res => {
    })

    promotions[item_index] = item_update
    this.setState({
      promotion_list: promotions
    })
  }

  render () {

    return (
      <div className="row">
        <div className="col-sm-7">
          <div className="card card-accent-info">
            <div className="card-header">
              <strong className="text-title">
                โปรโมชั่น
              </strong>
            </div>
            <div className="card-block" style={styles.overflowY}>
              <table className="table table-striped table-responsive">
                <thead>
                <tr>
                  <th className="text-center">#</th>
                  <th className="text-center">ชื่อโปรโมชั่น</th>
                  <th className="text-center text-nowrap">ลำดับการแสดง</th>
                  <th className="text-center">ใช้งาน</th>
                  <th className="text-center">จัดการ</th>
                </tr>
                </thead>
                <tbody>
                {
                  this.state.promotion_list.map((promotion, index) => (
                    <tr key={index}>
                      <td className="text-center" style={{width: 10}}>{ index+1 }</td>
                      <td className="text-nowrap" style={{width: 80}}>{ promotion.promotion_name }</td>
                      <td className="text-center text-nowrap" style={{width: 50}}>{ promotion.promotion_order }</td>
                      <td className="text-nowrap text-center" style={{width: 50}}>

                        { promotion.promotion_status === 'show' ? 'ใช้งาน' : 'ไม่ใช้งาน' }

                        <br/>

                        <Switch height = {20} width ={48}
                                onChange={(checked) => this.onChangeSwitch(checked, promotion)}
                                checked={promotion.promotion_status === 'show'}
                        />

                      </td>
                      <td className="text-center" style={{width: 120}}>
                        <button type="button"
                                className="btn btn-sm btn-warning btn-md-width mr-2 mb-2"
                                onClick={() => this.setEditForm(promotion)}
                        >
                          <i className="fa fa-edit"/> แก้ไข
                        </button>
                        <button type="button"
                                className="btn btn-sm btn-danger btn-md-width mr-2 mb-2"
                                onClick={() => this.deletePromotion(promotion)}
                        >
                          <i className="fa fa-remove"/> ลบ
                        </button>
                      </td>
                    </tr>
                  ))
                }
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="col-sm-5">
          <div className="card card-accent-success">
            <div className="card-header card-customer">
              <strong className="text-title">เพิ่ม/แก้ไข โปรโมชั่น</strong>
            </div>

            <div className="card-block">
              <div className="form-group">
                <label htmlFor="promotion_name">ชื่อโปรโมชั่น</label>
                <input
                  value={this.state.promotion_name}
                  type="text"
                  className="form-control"
                  name="promotion_name"
                  placeholder="ชื่อโปรโมชั่น"
                  style={styles.inputSearch}
                  onChange={(e) => {
                    this.setState({
                      promotion_name: e.target.value
                    })
                  }}
                />
              </div>

              <div className="form-group">
                <label htmlFor="promotion_order">ลำดับการแสดง</label>
                <input
                    value={this.state.promotion_order}
                    type="text"
                    className="form-control"
                    name="promotion_order"
                    placeholder="ลำดับการแสดง"
                    style={styles.inputSearch}
                    onChange={(e) => {
                      this.setState({
                        promotion_order: e.target.value
                      })
                    }}
                />
              </div>
            </div>

            <div className="card-footer text-right">

              <button type="button"
                      className="btn btn-sm btn-danger mr-2"
                      style={styles.button}
                      onClick={()=> this.clearForm()}
              >
                <i className="fa fa-refresh"/> ยกเลิก
              </button>

              <button type="button"
                      className={this.state.actionType==="แก้ไข"?  "btn btn-sm btn-warning" : "btn btn-sm btn-primary"}
                      style={styles.button}
                      onClick={() => {
                        this.savePromotion()
                      }}
              >
                <i className={this.state.actionType === "แก้ไข" ? "fa fa-edit" : "fa fa-save"}/> {this.state.actionType}
              </button>

            </div>

          </div>
        </div>
      </div>
    )
  }
}

const styles = {
  button: {
    borderRadius:5,
    height: 36,
  },
  inputSearch: {
    borderRadius:5,
  },
  overflowY: {
    overflow: 'scroll',
  }
}

export default SettingPromotion
