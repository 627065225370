import React, { Component } from 'react'
import {connect} from 'react-redux' ;
import { Switch, Route, Redirect  } from 'react-router-dom' //Link, BrowserRouter
import Header from '../../components/Header/'
import Sidebar from '../../components/Sidebar/'
import Breadcrumb from '../../components/Breadcrumb/'
// import Aside from '../../components/Aside/'
import Footer from '../../components/Footer/'
import CarList from '../../components/Cars/CarList'
import CarNew from '../../components/Cars/CarNew'
import CarDetail from '../../components/Cars/CarDetail'
import CarBook from '../../components/Cars/CarBook'
import CarHistory from '../../components/Cars/CarHistory'
import CarRepairMainList from '../../components/CarRepair/CarRepairMainList'
import CarRepairNew from  '../../components/CarRepair/CarRepairNew'
import CarRepairAfterSell from  '../../components/CarRepair/CarRepairAfterSell'
import CustomerList from '../../components/Customer/CustomerList'
import CustomerForm from '../../components/Customer/CustomerForm'
import CustomerDetail from '../../components/Customer/CustomerDetail'
import SalesList from '../../components/Sales/SalesList'
import SalesBuy from '../../components/Sales/SalesBuy'
import SalesDetail from '../../components/Sales/SalesDetail'
import CarFinanceList from '../../components/CarFinance/CarFinanceList'
import BankList from '../../components/Bank/BankList'
import MaintenanceCenterList from '../../components/MaintenanceCenter/MaintenanceCenterList'
import CalendarList from '../../components/Calendar/CalendarList'
import BranchList from '../../components/Branch/BranchList'
import AccountList from '../../components/Account/AccountList'
import Dashboard from '../../views/Dashboard'
import Dashboard2 from '../../views/Dashboard2'

import Charts from '../../views/Charts/'
import Widgets from '../../views/Widgets/'
import Buttons from '../../views/Components/Buttons/'
import Cards from '../../views/Components/Cards/'
import Forms from '../../views/Components/Forms/'
import Modals from '../../views/Components/Modals/'
import SocialButtons from '../../views/Components/SocialButtons/'
import Switches from '../../views/Components/Switches/'
import Tables from '../../views/Components/Tables/'
import Tabs from '../../views/Components/Tabs/'
import FontAwesome from '../../views/Icons/FontAwesome/'
import SimpleLineIcons from '../../views/Icons/SimpleLineIcons/'

import CarBookCancel from '../../components/Cars/CarBookCancel'
import DebtorsList from '../../components/Debtors/DebtorsList'
import DebtorsPaymentDetail from '../../components/Debtors/DebtorsPaymentDetail'
import ExpenseList from '../../components/Expense/ExpenseList'
import ExpenseRepair from '../../components/Expense/ExpenseRepair'
import ExpenseInstallment from '../../components/Expense/ExpenseInstallment'
import ExpenseCarOther from '../../components/Expense/ExpenseCarOther'
import CarExpenseList from '../../components/Cars/CarExpenseList'
import EmployeeList from '../../components/Employee/EmployeeList'
import EmployeeForm from '../../components/Employee/EmployeeForm'
import ReceiptsList from '../../components/Receipts/ReceiptsList'
import IncomeTaxInvoice from '../../components/Receipts/IncomeTaxInvoice'
import CarFeatureForm from '../../components/Cars/CarFeatureForm'
import SalesClaim from '../../components/Sales/SalesClaim'
import CarClaimList from '../../components/CarClaim/CarClaimList'
import CarReservationList from '../../components/Cars/CarReservation/CarReservationList'
import CarReservationTabs from '../../components/Cars/CarReservation/CarReservationTabs'
import Login from '../../components/Login/Login'
import SparePart from '../../components/SparePart/SparePart'
import CarInAll from '../../components/Cars/CarInAll'
import CarBuyContract from '../../components/Cars/CarBuyContract'
import CarInAllSummary from '../../components/Cars/CarInAllSummary'
import { PORT } from '../../config/config'
import CustomerFindCarList from '../../components/CustomerFindCar/CustomerFindCarList'
import CustomerFindCarAdd from '../../components/CustomerFindCar/CustomerFindCarAdd'
import AgentList from '../../components/Agent/AgentList'
import AgentCarDetail from '../../components/Agent/AgentCarDetail'
import SellReport from '../../components/Report/SellReport'
import CarInReport from '../../components/Report/CarInReport'
import CarInReportTabs from '../../components/Report/CarInReportTabs'
import ReportTab from '../../components/Report/ReportTab'
import TradingReport from '../../components/Report/TradingReport'
import CarRepairReport from '../../components/Report/CarRepairReport'
import CalendarManage from "../../components/Calendar/CalendarManage/CalendarManage";
import { logoutAndRedirect } from '../../actions'
import Setting from "../../components/Setting/Setting";
import CarbookReport from "../../components/Report/CarbookReport";
import DebtorsReport from "../../components/Report/DebtorsReport";
import CarRepairByCenter from "../../components/Report/CarRepairByCenter";

import FinanceTaxInvoice from "../../components/Sales/FinanceTaxInvoice";
import FinanceTaxTabs from "../../components/Sales/FinanceTaxTabs";

import CarSalesContract from "../../components/Sales/CarSalesContract";
import FinancePendingList from "../../components/FinancePending/FinancePendingList/FinancePendingList";
import FinancePendingAdd from "../../components/FinancePending/FinancePendingAdd/FinancePendingAdd";
import ReceiptsReport from "../../components/Report/ReceiptsReport";
import IncomeExpenseItemList from "../../components/IncomeExpenseItem/IncomeExpenseItemList";
import CarClaimItems from '../../components/CarClaim/CarClaimItems'
import SalesPayOther from "../../components/Sales/SalesPayOther/SalesPayOther";
import CarReceiptOcpd from "../../components/Sales/CarReceiptOcpd";
import CarRepairByRetailer from "../../components/Report/CarRepairByRetailer";
import CarSalesContract2 from "../../components/Sales/CarSalesContract2";
import CarSalesContractAt from "../../components/Sales/CarSalesContractAt";
import CarSalesContractCarOk from "../../components/Sales/CarSalesContractCarOk";
import CustomerOldList from "../../components/CustomerOld/CustomerOldList/CustomerOldList";
import CustomerStoreFrontList from "../../components/CustomerStoreFront/CustomerStoreFrontList/CustomerStoreFrontList";
import ExpenseMonthReport from "../../components/Expense/ExpenseMonthReport";

import CarCheck from '../../components/CarCheck'
import CarCheckList from '../../components/CarCheckList'
import CustomerFollowupTab from '../../components/CustomerFollowup/CustomerFollowupTab'
import CustomerFollowupList from '../../components/CustomerFollowup/CustomerFollowupList'
import CarCost from '../../components/Cars/CarCost/CarCost';
import DebtorsForm from "../../components/Debtors/DebtorsForm";
import EstimatedRevenue from "../../components/Sales/SalesList/EstimatedRevenue";
import TaxDocumentTabs from '../../components/TaxDocument/TaxDocumentTabs'
import TrackInformationTabs from '../../components/TrackInformation/TrackInformationTabs'
import PaymentVoucher from "../../components/PaymentVoucher/PaymentVoucher";
import PriceListTabs from "../../components/PriceList/PriceListTabs";
// const port = window.env.PORT;

class Full extends Component {

  constructor (props) {
    super(props)
    this.state = {
      user: {},
      user_type: '',
    }
  }

  componentWillMount () {
    this.checkAuth(this.props.isAuthenticated);
    try {
      if (this.props.user !== undefined && this.props.user ==='undefined') {
        // && this.props.user !== null
        console.log(this.props.user)
        let user = JSON.parse(this.props.user);

        this.setState({
          user: user ,
          user_type : user.type
        })
      }
    } catch (e) {
      this.props.onLogout()
    }

  }

  componentWillReceiveProps (nextProps) {
    this.checkAuth(nextProps.isAuthenticated);
  }

  checkAuth (isAuthenticated) {
    if (!isAuthenticated) {
      this.props.history.push('/login');
    }
  }

  render() {
    return (
      <div className="app">
        <Header history={this.props.history} />
        <div className="app-body">
          <Sidebar {...this.props}/>
          <main className="main">
            <Breadcrumb />
            <div className="container-fluid">

              <Switch history={this.props.history}    >

                <Route path="/dashboard" name="Dashboard2" component={(PORT==='9005')? Dashboard2: Dashboard }/>

                {/*<Route path="/components/buttons" name="Buttons" component={Buttons}/>
                <Route path="/components/cards" name="Cards" component={Cards}/>
                <Route path="/components/forms" name="Forms" component={Forms}/>
                <Route path="/components/modals" name="Modals" component={Modals}/>
                <Route path="/components/social-buttons" name="Social Buttons" component={SocialButtons}/>
                <Route path="/components/switches" name="Swithces" component={Switches}/>
                <Route path="/components/tables" name="Tables" component={Tables}/>
                <Route path="/components/tabs" name="Tabs" component={Tabs}/>
                <Route path="/icons/font-awesome" name="Font Awesome" component={FontAwesome}/>
                <Route path="/icons/simple-line-icons" name="Simple Line Icons" component={SimpleLineIcons}/>
                <Route path="/widgets" name="Widgets" component={Widgets}/>
                <Route path="/charts" name="Charts" component={Charts}/>*/}

                <Route path="/cars/list/:pg" name="CarListPage"  component={CarList} />
                <Route path="/cars/list" name="CarList"  component={CarList} />
                {/*<Route
                    exact
                    path="/cars/list/:pg"
                    render={({ match }) => (
                        <Redirect to={`/cars/list/${match.params.pg}`} />
                    )}
                />*/}

                <Route path="/cars/new" name="CarNew" component={CarNew}  />
                <Route path="/cars/detail" name="CarDetail" component={CarDetail}/>
                <Route path="/cars/buy-contract" name="CarBuyContract" component={CarBuyContract}/>
                <Route path="/car-repair/list" name="CarRepairMainList" component={CarRepairMainList}/>
                <Route path="/car-history" name="CarHistory" component={CarHistory}/>

                <Route path="/car-repair/new" name="CarRepairNew" component={CarRepairNew}/>
                <Route path="/car-check" name="CarCheck"  component={CarCheck} />
                <Route path="/carcheck/list" name="CarCheckList"  component={CarCheckList} />

                <Route path="/car-repair/after-sell" name="CarRepairAfterSell" component={CarRepairAfterSell}/>

                <Route path="/customer/list" name="CustomerList" component={CustomerList}/>
                <Route path="/customer/form" name="CustomerForm" component={CustomerForm}/>
                <Route path="/customer/detail" name="CustomerDetail" component={CustomerDetail}/>

                <Route path="/agent/list" name="AgentList" component={AgentList}/>
                <Route path="/agent/detail" name="AgentList" component={AgentCarDetail}/>

                <Route path="/sales/list" name="SalesList" component={SalesList}/>
                <Route path="/sales/buy" name="SalesBuy" component={SalesBuy}/>
                <Route path="/sales/claim" name="SalesClaim" component={SalesClaim}/>
                <Route path="/sales/estimated/revenue" name="EstimatedRevenue" component={EstimatedRevenue}/>

                <Route path="/carfinance/list" name="CarFinanceList" component={CarFinanceList}/>
                <Route path="/bank/list" name="BankList" component={BankList}/>
                <Route path="/maintenance/list" name="MaintenanceCenterList" component={MaintenanceCenterList}/>
                <Route path="/calendar/list" name="CalendarList" component={CalendarList}/>
                <Route path="/branch/list" name="BranchList" component={BranchList}/>
                <Route path="/account-list" name="AccountList" component={AccountList}/>
                <Route path="/car-sell/detail" name="SalesDetail" component={SalesDetail}/>
                <Route path="/cars/book" name="CarBook" component={CarBook}/>
                <Route path="/cars/book-cancel" name="CarBookCancel" component={CarBookCancel}/>
                <Route path="/debtors/list" name="DebtorsList" component={DebtorsList}/>
                <Route path="/debtors/form" name="DebtorsForm" component={DebtorsForm}/>
                <Route path="/debtors/payment-detail" name="DebtorsPaymentDetail" component={DebtorsPaymentDetail}  />
                <Route path="/expense/list" name="ExpenseList" component={ExpenseList}/>

                <Route path="/expense/repair" name="ExpenseRepair" component={ExpenseRepair}/>
                <Route path="/expense/installment" name="ExpenseInstallment" component={ExpenseInstallment}/>
                <Route path="/expense/car/other" name="ExpenseCarOther" component={ExpenseCarOther}/>

                <Route path="/car-expense/list" name="CarExpenseList" component={CarExpenseList}/>
                <Route path="/employee/list" name="EmployeeList" component={EmployeeList}/>
                <Route path="/employee/form" name="EmployeeForm" component={EmployeeForm}/>
                <Route path="/receipts/list" name="ReceiptsList" component={ReceiptsList}/>
                <Route path="/receipts/income-tax-invoice" name="IncomeTaxInvoice" component={IncomeTaxInvoice}/>
                <Route path="/car/feature" name="CarFeatureForm" component={CarFeatureForm}/>

                <Route path="/car-reservation/tabs" name="CarReservationTabs" component={CarReservationTabs}/>
                <Route path="/car-reservation/list" name="CarReservationList" component={CarReservationList}/>
                <Route path="/car-claim/list" name="CarClaimList" component={CarClaimList}/>
                <Route path="/car-claim/items" name="CarClaimItems" component={CarClaimItems}/>

                <Route path="/login" name="Login" component={Login}/>
                <Route path="/sparepart" name="SparePart" component={SparePart}/>
                <Route path="/carin/all" name="CarInAll"  component={PORT===''? CarInAllSummary : CarInAll}/>

                <Route path="/customer/findcar" name="CustomerFindCarList"  component={CustomerFindCarList}/>
                <Route path="/customer/findcar/add" name="CustomerFindCarAdd"  component={CustomerFindCarAdd}/>

                <Route path="/report/sellreport" name="SellReport"  component={SellReport}/>
                <Route path="/report/trading" name="SellReport"  component={TradingReport}/>

                <Route path="/report/carin" name="CarInReport"  component={CarInReport}/>
                <Route path="/report/carintabs" name="CarInReportTabs"  component={CarInReportTabs}/>

                <Route path="/report/carrepair" name="CarRepairReport"  component={CarRepairReport}/>
                <Route path="/report/carbook" name="CarbookReport"  component={CarbookReport}/>
                <Route path="/report/debtors" name="DebtorsReport"  component={DebtorsReport}/>
                <Route path="/report/tabs" name="ReportTab"  component={ReportTab}/>
                <Route path="/report/receipts" name="ReceiptsReport"  component={ReceiptsReport}/>
                <Route path="/report/expense/month" name="ExpenseMonthReport"  component={ExpenseMonthReport}/>

                <Route path="/calendar/manage" name="CalendarManage"  component={CalendarManage}/>

                <Route path="/setting" name="Setting"  component={Setting} />

                <Route path="/finance-tax-invoice" name="FinanceTaxInvoice"  component={FinanceTaxInvoice} />
                <Route path="/finance-tax/tabs" name="FinanceTaxTabs"  component={FinanceTaxTabs} />

                <Route path="/car-sales-contract" name="CarSalesContract"  component={CarSalesContract} />
                <Route path="/car-sales-contract-at" name="CarSalesContractAt"  component={CarSalesContractAt} />

                <Route path="/car-sales-contract2" name="CarSalesContract2"  component={CarSalesContract2} />
                <Route path="/car-sales-contract-carok" name="CarSalesContractCarOk"  component={CarSalesContractCarOk} />

                <Route path="/finance-pending/list" name="FinancePendingList" component={FinancePendingList}/>
                <Route path="/finance-pending/add" name="FinancePendingAdd" component={FinancePendingAdd}/>

                <Route path="/income_expense_item" name="IncomeExpenseItemList" component={IncomeExpenseItemList}/>

                <Route path="/report/carrepairbycenter" name="CarRepairByCenter" component={CarRepairByCenter}/>

                <Route path="/car-sell-pay-other/detail" name="SalesPayOther" component={SalesPayOther}/>

                <Route path="/car-receipt-ocpd" name="CarReceiptOcpd"  component={CarReceiptOcpd} />

                <Route path="/report/carrepairbyretailer" name="CarRepairByRetailer" component={CarRepairByRetailer}/>

                <Route path="/customer-old/list" name="CustomerOldList" component={CustomerOldList}/>

                <Route path="/customer/storefront" name="CustomerStoreFront" component={CustomerStoreFrontList}/>

                <Route path="/customer/followup/tab" name="CustomerFollowupTab"  component={CustomerFollowupTab}/>
                <Route path="/customer/followup/list" name="CustomerFollowupList" component={CustomerFollowupList}/>
                <Route path="/car-cost" name="CarCost" component={CarCost}/>

                <Route path="/tax-document-tabs" name="TaxDocumentTabs" component={TaxDocumentTabs}/>
                <Route path="/trackinformation/tabs" name="TrackInformationTabs" component={TrackInformationTabs}/>
                <Route path="/pricelist/tabs" name="PriceListTabs" component={PriceListTabs}/>

                <Route path="/payment-voucher" name="PaymentVoucher"  component={PaymentVoucher} />

                <Redirect from="/" to="/login" />

              </Switch>

            </div>
          </main>
          {/* <Aside /> */}
        </div>
        <Footer />
      </div>
    );
  }
}


const mapStateToProps = (state) => ({
  user: state.auth.user,
  token: state.auth.token,
  isAuthenticated: state.auth.isAuthenticated
})

const mapDispatchToProps = (dispatch) => ({
  // onLoginSuccess(data) {
  //   dispatch(loginUserSuccess(data.token,JSON.stringify(data.user)))
  // },
  // onChangeUserType(type, user) {
  //   dispatch(changeUserType(type, user))
  // },
  onLogout() {
    dispatch(logoutAndRedirect())
  }
})


export default connect(mapStateToProps, mapDispatchToProps)(Full);
//export default Full;

//
//<Route path="/login" name="Login" component={Dashboard}/>
